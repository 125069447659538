import { StoreAction } from "../../@core/constants/action";

const initialState = {
  categoryDataList: [],
  languagesDataList: [],
  tempCategoryDataList: [],
  templateList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_CATEGORY:
      return { ...state, categoryDataList: action.payload };
    case StoreAction.SET_LANGUAGES:
      return { ...state, languagesDataList: action.payload };
    case StoreAction.GET_TEMP_CATEGORY:
      return { ...state, tempCategoryDataList: action.payload };
    case StoreAction.SET_TEMPLATE_LIST:
      return { ...state, templateList: action.payload };
    default:
      return state;
  }
}
